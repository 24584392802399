<template>
  <div class="borde">
    <template v-if="elemento.tipo === undefined">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            color="green darken-2"
            v-bind="attrs"
            v-on="on"
            @click="agregarNodos(elemento)"
          >
            mdi-sitemap
          </v-icon>
        </template>
        <span>Agregar Agrupación AND/OR</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            color="green darken-2"
            v-bind="attrs"
            v-on="on"
            @click="dialog = true"
          >
            mdi-state-machine
          </v-icon>
        </template>
        <span>Aplicar Función</span>
      </v-tooltip>
    </template>

    <template v-if="elemento.tipo === 'fn'">
      {{ elemento.texto }}
    </template>

    <span v-if="elemento.tipo === 'opl'">
      <span class="sup">(</span>
      <template v-for="(item, index) in elemento.nodos" style="float: left">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="ma-2"
              small
              color="#eaeaea"
              text-color="red"
              v-bind="attrs"
              v-on="on"
              v-if="index !== 0"
              @click="cambiarOperador(elemento, index)"
            >
              {{ elemento.operadores[index - 1] }}
            </v-chip>
          </template>
          <span>Clic para cambiar</span>
        </v-tooltip>

        <nodo :elemento="item" :fuentes="fuentes" :funciones="funciones"></nodo>
      </template>
      <span class="sup">)</span>
    </span>
    <!-- <v-tooltip top v-if="elemento.tipo !== undefined">
      <template v-slot:activator="{ on, attrs }">
        <button @click="reset(elemento)" v-bind="attrs" v-on="on" class="sup">
          <sup>-</sup>
        </button>
      </template>
      <span>Reset</span>
    </v-tooltip> -->
    <button
      v-if="elemento.tipo !== undefined"
      @click="reset(elemento)"
      class="sup"
    >
      <sup>x</sup>
    </button>
    <button
      style="margin-left:5px"
      v-if="elemento.tipo == 'opl'"
      @click="agregarOperador(elemento)"
      class="sup"
    >
      <sup>+</sup>
    </button>

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title class="text-h5">
          Selección de función a aplicar
        </v-card-title>

        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="elemento.funcion"
                    :items="funciones"
                    :item-text="'valor_texto'"
                    :item-value="'id'"
                    attach
                    label="Función"
                    :rules="required"
                    return-object
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="elemento.fuente"
                    :items="fuentes"
                    :item-text="'nombre'"
                    :item-value="'id'"
                    attach
                    label="Fuente"
                    :rules="required"
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <div
                    v-if="loadingVariables"
                    class="spinner-border"
                    role="status"
                  ></div>
                  <v-select
                    v-if="!loadingVariables"
                    v-model="elemento.variable"
                    :items="variables"
                    :item-text="'nombre'"
                    :item-value="'id'"
                    attach
                    label="Variable"
                    :rules="required"
                    return-object
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                   <v-switch v-model="usarConstante" :label="`Usar una constante de comparación`"></v-switch>
                </v-col>
              </v-row>
              <v-row v-if="usarConstante">
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="elemento.constante_comparacion"
                    label="Constante Comparación"
                    :rules="
                      required.concat(
                        (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                      )
                    "
                    :counter="100"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="!usarConstante">
                <v-col cols="12" md="6">
                  <v-select
                    v-model="elemento.fuente_comparacion"
                    :items="fuentes"
                    :item-text="'nombre'"
                    :item-value="'id'"
                    attach
                    label="Fuente Comparacion"
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <div
                    v-if="loadingVariablesComparacion"
                    class="spinner-border"
                    role="status"
                  ></div>
                  <v-select
                    v-if="!loadingVariablesComparacion"
                    v-model="elemento.variable_comparacion"
                    :items="variablesComparacion"
                    :item-text="'nombre'"
                    :item-value="'id'"
                    attach
                    label="Variable"
                    return-object
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            :disabled="!valid"
            @click="agregarFuncion"
          >
            Aplicar Función
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NodoOpl from "./NodoOpl.js";
import VariableService from "@/services/variable.service";

export default {
  name: "nodo",
  props: {
    elemento: {
      type: Object,
      default: () => new NodoOpl(),
    },
    fuentes: {
      type: Array,
      default: () => new Array(),
    },
    funciones: {
      type: Array,
      default: () => new Array(),
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      usarConstante: false,
      variables: [],
      variablesComparacion: [],
      loadingVariables: false,
      loadingVariablesComparacion: false,
    };
  },
  components: {},
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {
    "elemento.fuente.id"(newVal) {
      this.fetchVariables(`?layout_id=${this.elemento.fuente.id}`);
    },
    "elemento.fuente_comparacion.id"(newVal) {
      this.fetchVariablesComparacion(`?layout_id=${this.elemento.fuente_comparacion.id}`);
    },
    usarConstante(newVal) {
      if (!newVal) {
        this.elemento.constante_comparacion = undefined
      } 
    }
  },
  mounted() {},
  methods: {
    agregarNodos(nodo) {
      nodo.tipo = "opl";
      nodo.operadores.push("AND");
      nodo.nodos.push(new NodoOpl());
      nodo.nodos.push(new NodoOpl());
      // nodo.agregarNodos();
    },
    agregarOperador(nodo) {
      nodo.operadores.push("AND");
      nodo.nodos.push(new NodoOpl());
    },
    agregarFuncion() {
      console.log('Elemento...')
      console.log(this.elemento)
        this.dialog = false;

        this.elemento.tipo = 'fn'
        this.elemento.texto = this.elemento.funcion.descripcion
        this.elemento.variable? this.elemento.texto = this.elemento.texto.replace('VarX', this.elemento.variable.nombre):''
        this.usarConstante? this.elemento.texto = this.elemento.texto.replace('VarY', this.elemento.constante_comparacion):''
        this.elemento.variable_comparacion? this.elemento.texto = this.elemento.texto.replace('VarY', this.elemento.variable_comparacion.nombre):''
        this.elemento.fuente_comparacion? this.elemento.texto = this.elemento.texto.replace('FuenteY', this.elemento.fuente_comparacion.nombre):''
        // this.elemento.agregarFuncion();
    },
    cambiarOperador(elemento, index) {
      if (elemento.operadores[index - 1] === "AND") {
        elemento.operadores[index - 1] = "OR";
      } else {
        elemento.operadores[index - 1] = "AND";
      }
      this.$forceUpdate();
    },
    reset(elemento) {
      elemento.tipo = undefined;
      elemento.operadores = [];
      elemento.nodos = [];

      elemento.funcion = undefined;
      elemento.texto = undefined;
      elemento.fuente = undefined;
      elemento.variable = undefined;
      elemento.fuente_comparacion = undefined;
      elemento.variable_comparacion = undefined;
      elemento.constante_comparacion = undefined;
    },
    fetchVariables(cadenaBusqueda) {
      this.loadingVariables = true;
      // VariableService.getItems(this.elemento.fuente_id, cadenaBusqueda).then(
      VariableService.getItems(cadenaBusqueda).then(
        (response) => {
          console.log(cadenaBusqueda)
          console.log('Variables:')
          console.log(response.data)
          this.variables = response.data.map(function f(x) {
            return {
              id: x.id,
              nombre: x.nombre,
              archivo: x.archivo,
              archivo_id: x.archivo_id
              // layout_id: x.layout.id,
              // layout_nombre: x.layout.nombre,
            };
          });
          //   this.variables = response.data;
          this.loadingVariables = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingVariables = false;
        }
      );
    },
    fetchVariablesComparacion(cadenaBusqueda) {
      this.loadingVariablesComparacion = true;
      // VariableService.getItems(this.elemento.fuente_comparacion_id, cadenaBusqueda).then(
      VariableService.getItems(cadenaBusqueda).then(
        (response) => {
          this.variablesComparacion = response.data.map(function f(x) {
            return {
              id: x.id,
              nombre: x.nombre,
              archivo: x.archivo,
              archivo_id: x.archivo_id
              // layout_id: x.layout.id,
              // layout_nombre: x.layout.nombre,
            };
          });
          //   this.variablesComparacion = response.data;
          this.loadingVariablesComparacion = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingVariablesComparacion = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.borde {
  border: 1px solid #eaeaea;
  display: inline-block;
  margin: 5px;
}

.sup {
  font-size: 24px;
  font-weight: bold;
  color: #999;
}
</style>